import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./ResetPassword.css";
import validateResetPassword from "./ResetPasswordValidation";

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [success, setSuccess] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [topic, setTopic] = useState<string>("");
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Hae token URL:stä
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    setToken(token);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (topic !== "") {
      return;
    }
    // Validate form values
    const validationErrors = validateResetPassword({
      password,
      confirmPassword,
    });
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      const response = await axios.post("/api/reset-password", {
        token,
        newPassword: password,
      });

      if (response.data.success) {
        setSuccess(true);
        setTimeout(() => navigate("/login"), 3000); // Ohjaa käyttäjä kirjautumissivulle 3 sekunnin jälkeen
      }
    } catch (error) {
      setError(
        "Failed to reset password. The token might be invalid or expired."
      );
    }
  };

  return (
    <div className="reset-password-wrapper">
      <span className="reset-password-title" onClick={() => navigate("/login")}>
        Pirpana
      </span>
      <div className="reset-password-container mt-4">
        <div className="reset-password-header">
          <h2>Reset Password</h2>
          <button
            type="button"
            className="reset-password-close-btn"
            onClick={() => navigate("/login")}
          >
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit} className="reset-password-form">
          {error && (
            <div className="error-box">
              <p className="error">{error}</p>
            </div>
          )}
          {errors.password && (
            <div className="error-box">
              <p className="error">{errors.password}</p>
            </div>
          )}
          {errors.confirmPassword && (
            <div className="error-box">
              <p className="error">{errors.confirmPassword}</p>
            </div>
          )}
          <div className="form-group">
            <label htmlFor="password">New Password</label>
            <div className="password-input-wrapper">
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="form-input"
              />
              <button
                type="button"
                className="password-toggle-btn"
                onClick={() => setShowPassword(!showPassword)}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </button>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <div className="password-input-wrapper">
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                className="form-input"
              />
              <button
                type="button"
                className="password-toggle-btn"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <FontAwesomeIcon
                  icon={showConfirmPassword ? faEyeSlash : faEye}
                />
              </button>
            </div>
            <div>
              <input
                type="text"
                id="topic"
                style={{ display: "none" }}
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
                tabIndex={-1}
                autoComplete="off"
              />
            </div>
          </div>
          <button type="submit" className="reset-password-button">
            Reset Password
          </button>
        </form>
        {success && (
          <p className="success-message">
            Password has been reset successfully. Redirecting to login page...
          </p>
        )}
        <div className="info-box">
          <br />
          <p>
            You have requested to reset your password. Please make sure your new
            password is strong and secure. If you did not request this change,
            please contact our support team immediately.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
