import React, { useState, ChangeEvent, FormEvent } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import validation from "./LoginValidation";
import axios from "axios";
import "./Profile.css";

interface FormValues {
  email: string;
  password: string;
}

const Loginfile: React.FC = () => {
  const [values, setValues] = useState<FormValues>({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [topic, setTopic] = useState<string>("");

  const server = process.env.REACT_APP_SERVER;

  const navigate = useNavigate();

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (topic !== "") {
      return;
    }
    const validationErrors = validation(values);
    setErrors(validationErrors);

    // Jos ei ole virheitä, voit jatkaa lomakkeen käsittelyä tässä
    if (Object.keys(validationErrors).length === 0) {
      // lähetä lomakkeen tiedot palvelimelle
      axios
        .post(`${server}/api/login`, values)
        .then((res) => {
          const { token, refreshToken, userId, isVerified } = res.data;
          const tokenExpiry = Date.now() + 4 * 60 * 60 * 1000; // 4 tuntia millisekunteina
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("tokenExpiry", tokenExpiry.toString());
          localStorage.setItem("userId", userId);
          localStorage.setItem("isVerified", isVerified.toString());
          // päivittää sivun
          window.location.reload();
          // Navigoi "/profile"-polulle onnistuneen kirjautumisen jälkeen
          navigate("/profile");
        })
        .catch((err) => {
          if (err.response && err.response.status === 401) {
            // Käyttäjän kirjautuminen epäonnistui (Unauthorized)
            alert("Invalid email or password. Please try again.");
          } else {
            // Muut virheet
            console.log(err);
            alert(
              "An error occurred while processing your request. Please try again later."
            );
          }
        });
    }
  };

  const handleForgotPassword = async () => {
    let email = prompt("Please enter your email address:");

    if (email) {
      email = email.trim();
      if (validateEmail(email)) {
        try {
          const response = await axios.post("/api/forgot-password", { email });

          if (response.status === 200) {
            console.log(`Password reset link sent to: ${email}`);
            alert("A password reset link has been sent to your email address.");
          } else {
            alert("Something went wrong. Please try again later.");
          }
        } catch (error) {
          console.error("Error sending password reset request:", error);
          alert(
            "There was an error processing your request. Please try again later."
          );
        }
      } else {
        alert("Please enter a valid email address.");
      }
    }
  };

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  return (
    <div className="d-flex justify-content-center align-items-start vh-100 pt-5">
      <Helmet>
        <title>Login - Pirpana</title>
        <meta
          name="description"
          content="Log in to Pirpana to access your personal information, items and manage your notifications."
        />
        <meta name="keywords" content="login, user account, Pirpana" />
      </Helmet>
      <div className="bg-white m-3 rounded border shadow p-4">
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <p className="d-flex align-items-center justify-content-center">
              Log In
            </p>
            <label htmlFor="email">
              <strong>Email</strong>
            </label>
            <input
              type="email"
              placeholder="Enter Email"
              name="email"
              value={values.email}
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {errors.email && <p className="text-danger">{errors.email}</p>}
          </div>
          <div>
            <input
              type="text"
              id="topic"
              style={{ display: "none" }}
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              tabIndex={-1}
              autoComplete="off"
            />
          </div>
          <div className="mb-3">
            <label htmlFor="password">
              <strong>Password</strong>
            </label>
            <input
              type="password"
              placeholder="Enter Password"
              name="password"
              value={values.password}
              onChange={handleInput}
              className="form-control rounded-0"
            />
            {errors.password && (
              <p className="text-danger">{errors.password}</p>
            )}
          </div>
          <button type="submit" className="btn btn-success w-100">
            Log in
          </button>
          <p>You agree to our terms and policies</p>
          <Link
            to="/signup"
            className="btn btn-default border w-100 text-decoration-none"
          >
            Create Account
          </Link>
          <p className="mt-4 text-center mb-0 tsm">
            <a
              href="#"
              onClick={handleForgotPassword}
              className="text-decoration-none footer-link "
            >
              Forgot Password?
            </a>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Loginfile;
