import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "./Profile.css";
import validateResetPassword from "./ResetPasswordValidation";
import profiledefault from "./images/profile.png";
import profileBG from "./images/profileBG.png";
import coconutSeller from "./images/sellergirl.jpg";
import editPen from "./images/editpen.png";
import icecreamman from "./images/cartseller.jpg";
import add from "./images/add.png";
import search from "./images/search.png";
import lista from "./images/lista.png";
import messagespic from "./images/mailicon.png";

const FETCH_INTERVAL = 1000; // 1 second

const Profile = () => {
  const [userInfo, setUserInfo] = useState({
    idusers: "",
    password: "",
    username: "",
    email: "",
    country: "",
    city: "",
    registrationdate: "",
    accountdeleted: "",
    profilepictureurl: "",
    isverified: 0,
    receivemessagenotifications: "no",
    receivenewsletter: "no",
  });

  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [profilePic, setProfilePic] = useState<string>(
    userInfo.profilepictureurl || profiledefault
  );
  const [passwordChange, setPasswordChange] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [originalEmail, setOriginalEmail] = useState("");
  const [validationErrors, setValidationErrors] = useState<{
    [key: string]: string;
  }>({});
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const lastFetchTimeRefUser = useRef<number | null>(null);
  const lastFetchTimeRefEmails = useRef<number | null>(null);

  const server = process.env.REACT_APP_SERVER;

  const handleChangePassword = () => {
    setIsEditingPassword(false);
    setPasswordChange({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  };

  const handleCancelPasswordChange = () => {
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
    setValidationErrors({});
    handleChangePassword();
  };

  const handleSavePasswordChange = () => {
    const newValidationErrors: { [key: string]: string } = {};

    if (passwordChange.oldPassword === "") {
      newValidationErrors.oldPassword = "Old Password is required";
    }

    if (Object.keys(newValidationErrors).length > 0) {
      setValidationErrors(newValidationErrors);
      return;
    }
    const resetPasswordValues = {
      password: passwordChange.newPassword,
      confirmPassword: passwordChange.confirmPassword,
    };

    const errors = validateResetPassword(resetPasswordValues);

    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setValidationErrors({});

    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found");
      navigate("/login");
      return;
    }

    axios
      .post(
        `${server}/api/user/update-password`,
        {
          idusers: userInfo.idusers,
          oldPassword: passwordChange.oldPassword,
          newPassword: passwordChange.newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("Password updated successfully on the server");
        setValidationErrors({});
        alert("Password updated successfully");
        handleChangePassword();
      })
      .catch((err) => {
        console.error("Error updating password on the server:", err);
        alert("Error updating password on the server");
      });
  };

  const navigate = useNavigate();

  const handleClickMyItems = () => {
    const iv = localStorage.getItem("isVerified");
    if (iv === "1") {
      navigate("/myitems");
    } else {
      alert("You need to verify your email account first to see 'My items'");
    }
  };

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("tokenExpiry");
      localStorage.removeItem("notif");
      localStorage.removeItem("verif");
      localStorage.removeItem("isVerified");
      window.location.reload();
      return;
    }

    try {
      const response = await axios.post(`${server}/api/refresh-token`, {
        refreshToken,
      });
      const { token, newRefreshToken } = response.data;

      // Päivitä token ja sen vanhenemisaika
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", newRefreshToken);
      localStorage.setItem(
        "tokenExpiry",
        (Date.now() + 4 * 60 * 60 * 1000).toString()
      ); // 4 tuntia eteenpäin
    } catch (err) {
      console.error("New token error:", err);
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("tokenExpiry");
      localStorage.removeItem("notif");
      localStorage.removeItem("verif");
      localStorage.removeItem("isVerified");
      window.location.reload();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowVerifyButton(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      const now = Date.now();

      // Fetch user info if more than FETCH_INTERVAL has passed
      if (
        !lastFetchTimeRefUser.current ||
        now - lastFetchTimeRefUser.current > FETCH_INTERVAL
      ) {
        lastFetchTimeRefUser.current = now;

        axios
          .get(`${process.env.REACT_APP_SERVER}/api/user`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            if (res.data && res.data.username && res.data.email) {
              setUserInfo((prevUserInfo) => ({
                ...prevUserInfo,
                idusers: res.data.idusers,
                password: res.data.password,
                username: res.data.username,
                email: res.data.email,
                country: res.data.country,
                city: res.data.city,
                registrationdate: res.data.registrationdate,
                accountdeleted: res.data.accountdeleted,
                profilepictureurl: res.data.profilepictureurl,
                isverified: res.data.isVerified,
              }));
              setOriginalEmail(res.data.email);

              // Fetch email settings if more than FETCH_INTERVAL has passed
              const nowEmails = Date.now();
              if (
                !lastFetchTimeRefEmails.current ||
                nowEmails - lastFetchTimeRefEmails.current > FETCH_INTERVAL
              ) {
                lastFetchTimeRefEmails.current = nowEmails;

                axios
                  .get(`${process.env.REACT_APP_SERVER}/api/get-allowemails`, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then((res) => {
                    if (res.data) {
                      setUserInfo((prevUserInfo) => ({
                        ...prevUserInfo,
                        receivemessagenotifications:
                          res.data.newmessage === 1 ? "yes" : "no",
                        receivenewsletter:
                          res.data.newsletter === 1 ? "yes" : "no",
                      }));
                    }
                  })
                  .catch((err) => {
                    console.error("Error fetching email settings:", err);
                  });
              }
            } else {
              console.error("Invalid user data in the response");
            }
          })
          .catch((err) => {
            console.error("Error fetching user info:", err);
            navigate("/login");
          });
      }
    } else {
      // If no token, redirect to login page
      navigate("/login");
    }
  }, [navigate]);

  const handleUpdateProfilePicture = async () => {
    const fileInput = document.getElementById("profilePictureInput");

    if (
      fileInput instanceof HTMLInputElement &&
      fileInput.files &&
      fileInput.files.length > 0
    ) {
      const selectedFile = fileInput.files[0];

      const compressImage = (file: File): Promise<Blob> => {
        return new Promise((resolve) => {
          const img = document.createElement("img");
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            if (!ctx) {
              throw new Error("Failed to get 2D context");
            }

            const MAX_WIDTH = 800;
            const scaleSize = MAX_WIDTH / img.width;
            canvas.width = MAX_WIDTH;
            canvas.height = img.height * scaleSize;

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            const quality = 0.7;

            canvas.toBlob(
              (blob) => {
                if (!blob) {
                  resolve(new Blob());
                  return;
                }
                resolve(blob);
              },
              "image/jpeg",
              quality
            );
          };
        });
      };

      const compressedBlob = await compressImage(selectedFile);

      const formData = new FormData();
      formData.append("profilePicture", compressedBlob, selectedFile.name);
      formData.append("userId", userInfo.idusers);

      axios
        .post(`${server}/api/user/upload-profile-picture`, formData)
        .then((res) => {
          console.log("Profile picture updated successfully");
          setUserInfo((prevInfo) => ({
            ...prevInfo,
            profilepictureurl: `/server/profile-images/${res.data.fileName}`,
          }));
          window.location.reload();
        })
        .catch((err) => {
          console.error("Error updating profile picture:", err);
        });
    } else {
      console.error("File input not found or no files selected");
    }
  };

  useEffect(() => {
    if (userInfo.profilepictureurl) {
      setProfilePic(
        `${process.env.REACT_APP_SERVER}/${userInfo.profilepictureurl}`
      );
    } else {
      setProfilePic(profiledefault);
    }
  }, [userInfo.profilepictureurl]);

  const handleLogout = () => {
    // Poista token local storagesta
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("tokenExpiry");
    localStorage.removeItem("notif");
    localStorage.removeItem("verif");
    localStorage.removeItem("isVerified");
    window.location.reload();
    // Ohjaa käyttäjä kirjautumissivulle
    navigate("/login");
  };

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const getStatus = () => {
    if (userInfo.accountdeleted === null) {
      return "";
    } else {
      return "\n" + userInfo.accountdeleted;
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSaveNotificationSettings = () => {
    const token = localStorage.getItem("token");

    if (token) {
      // Muuta arvot "yes" ja "no" vastaamaan tietokannan arvoja (1 ja 0)
      const receiveMessageNotificationsValue =
        userInfo.receivemessagenotifications === "yes" ? 1 : 0;
      const receiveNewsletterValue =
        userInfo.receivenewsletter === "yes" ? 1 : 0;

      axios
        .post(
          `${server}/api/user-allowemails/update`,
          {
            users_idusers: userInfo.idusers,
            newmessage: receiveMessageNotificationsValue,
            newsletter: receiveNewsletterValue,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(
            "Notification settings updated successfully on the server"
          );
          // Voit halutessasi lisätä jotain feedbackiä käyttäjälle
        })
        .catch((err) => {
          console.error(
            "Error updating notification settings on the server:",
            err
          );
        });
    } else {
      console.error("Token not found");
      navigate("/login");
    }
  };

  const handleSaveChanges = () => {
    const token = localStorage.getItem("token");

    if (token) {
      axios
        .post(
          `${server}/api/user/update`,
          {
            idusers: userInfo.idusers,
            username: userInfo.username,
            email: userInfo.email,
            country: userInfo.country,
            city: userInfo.city,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("User info updated successfully on the server");
          setIsEditing(false);
          refreshToken();

          if (userInfo.email !== originalEmail) {
            handleLogout(); // Kirjaa käyttäjä ulos, jos sähköposti on muuttunut
          }
          handleSaveNotificationSettings();
        })
        .catch((err) => {
          console.error("Error updating user info on the server:", err);
        });
    } else {
      console.error("Token not found");
      navigate("/login");
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    // Palauta alkuperäiset tiedot
    setUserInfo((prevInfo) => ({
      ...prevInfo,
      idusers: prevInfo.idusers,
      username: prevInfo.username,
      email: prevInfo.email,
      country: prevInfo.country,
      city: prevInfo.city,
      registrationdate: prevInfo.registrationdate,
      accountdeleted: prevInfo.accountdeleted,
      profilepictureurl: prevInfo.profilepictureurl,
      isverified: prevInfo.isverified,
      receivemessagenotifications: prevInfo.receivemessagenotifications,
      receivenewsletter: prevInfo.receivenewsletter,
    }));

    // Aseta isEditing-tila takaisin false-ksi
    setIsEditing(false);
  };

  const handleDelete = async () => {
    const token = localStorage.getItem("token");
    const userId = localStorage.getItem("userId");
    let myItemsArray: {
      iditems: number;
      itemtitle: string;
      price: number | null;
    }[] = [];

    try {
      if (!userId || !token) {
        throw new Error("User ID or token not found in localStorage");
      }

      // Lähetä pyyntö backendiin hakeaksesi käyttäjän tuotteet
      const itemsResponse = await axios.get(
        `${process.env.REACT_APP_SERVER}/api/myitems`,
        {
          params: { userId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const items = itemsResponse.data;
      myItemsArray = items.map((item: any) => ({
        iditems: item.iditems,
        itemtitle: item.itemtitle,
        price: item.price || null,
      }));
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error:", error.response?.data || error.message);
        alert(
          `Failed to fetch items: ${
            error.response?.data?.message || error.message
          }`
        );
      } else {
        console.error("General error:", error);
        alert("An unexpected error occurred.");
      }
    }

    if (myItemsArray) {
      const confirmText =
        `You have the following items listed:\n\n` +
        myItemsArray
          .map(
            (item) =>
              `● ${item.itemtitle} (id${item.iditems}) ${
                item.price !== null ? item.price + "PHP" : "No price"
              }`
          )
          .join("\n") +
        `\n\nIf you delete your account, your item listings will also be removed. Do you really want to continue?`;

      const confirmItems = window.confirm(confirmText);

      if (!confirmItems) {
        setIsEditing(false);
        return;
      }
    }

    const confirmDelete = window.confirm(
      "Deleting your user account will affect all services and features related to your account. This may include the loss of all saved settings, and other personal information.\n\nAre you sure you want to delete user data?"
    );

    if (confirmDelete) {
      if (token) {
        axios
          .post(
            `${server}/api/user/delete`,
            {
              // TÄMÄ RIVI AIHEUTTAA VIRHEEN JA KUVIA EI POISTETA ONNISTUNEESTI idusers: userInfo.idusers,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log("User deleted successfully on the server");

            if (myItemsArray.length > 0) {
              alert(myItemsArray.map((item) => item.iditems));
              axios
                .post(
                  `${server}/api/user/delete-items-and-images`,
                  { iditems: myItemsArray.map((item) => item.iditems) },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then(() => {
                  console.log(
                    "Items and images deleted successfully on the server"
                  );
                })
                .catch((err) => {
                  console.error("Error deleting items on the server:", err);
                });
            }
          })
          .catch((err) => {
            console.error("Error deleting user on the server:", err);
          });
      } else {
        console.error("Token not found");
        navigate("/login");
      }

      setIsEditing(false);
      handleLogout();
    } else {
      setIsEditing(false);
    }
  };

  const handleResendVerificationEmail = () => {
    setDisableButton(true);
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("Token not found");
      navigate("/login");
      return;
    }

    axios
      .post(
        `${server}/api/resend-verification`,
        {
          email: userInfo.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        alert("Verification email resent. Please check your email.");
      })
      .catch((err) => {
        console.error("Error resending verification email:", err);
        alert("Error resending verification email");
      });
  };

  const [disableButton, setDisableButton] = useState(false); // State disableButtonin alustaminen false-arvolla

  useEffect(() => {
    if (disableButton) {
      const timeout = setTimeout(() => {
        setDisableButton(false); // Asetetaan disableButton takaisin falseksi 1 minuutin kuluttua
      }, 60000); // 60000 millisekuntia on 1 minuutti
      return () => clearTimeout(timeout); // Tyhjennetään timeout, kun komponentti puretaan
    }
  }, [disableButton]);

  const handleAddItemButtonClick = () => {
    const iv = localStorage.getItem("isVerified");
    if (iv === "1") {
      window.location.href = "/newitem";
    } else {
      alert("You need to verify your email account first to sell your items");
    }
  };

  const handleMainPageButtonClick = () => {
    window.location.href = "/";
  };

  const handleMessagesPageButtonClick = () => {
    const iv = localStorage.getItem("isVerified");
    if (iv === "1") {
      window.location.href = "/messages";
    } else {
      alert("You need to verify your email account first to see your messages");
    }
  };

  return (
    <div className="container mt-3" style={{ maxWidth: "35rem" }}>
      <Helmet>
        <title>Profile - Pirpana</title>
        <meta
          name="description"
          content="View and edit your profile on Pirpana. Update your personal information, profile picture, and manage your account settings."
        />
        <meta name="keywords" content="profile, account settings, Pirpana" />
      </Helmet>
      <div className="card card-body mb-4">
        <div
          className="d-flex justify-content-center mb-3"
          style={{
            backgroundImage: `url(${profileBG})`,
          }}
        >
          <label htmlFor="profilePictureInput">
            <img
              src={profilePic}
              loading="lazy"
              alt="profile"
              className="img-fluid rounded-circle img-thumbnail"
              style={{
                width: "150px",
                height: "150px",
                margin: "0 auto",
                cursor: "pointer",
                objectFit: "cover",
              }}
            />
          </label>
          <input
            type="file"
            id="profilePictureInput"
            style={{ display: "none" }}
            onChange={handleUpdateProfilePicture}
          />
        </div>

        <div>
          <strong>Username</strong>{" "}
          {isEditing ? (
            <input
              type="text"
              className="form-control"
              value={userInfo.username}
              onChange={(e) =>
                setUserInfo({ ...userInfo, username: e.target.value })
              }
            />
          ) : (
            <p className="form-control-plaintext">{userInfo.username}</p>
          )}
        </div>
        <p></p>
        <div>
          <strong>Email</strong>{" "}
          {isEditing ? (
            <>
              <input
                type="text"
                className="form-control"
                value={userInfo.email}
                onChange={(e) =>
                  setUserInfo({ ...userInfo, email: e.target.value })
                }
              />
              <small className="text-danger">
                Changing your email requires you to log in again
              </small>
            </>
          ) : (
            <p className="form-control-plaintext">{userInfo.email}</p>
          )}
        </div>
        <p></p>
        <div className="d-flex justify-content-between">
          <div className="w-50 " style={{ paddingRight: "0.5rem" }}>
            <div>
              <strong>Country</strong>{" "}
              {isEditing ? (
                <input
                  type="text"
                  className="form-control"
                  value={userInfo.country}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, country: e.target.value })
                  }
                />
              ) : (
                <p className="form-control-plaintext">{userInfo.country}</p>
              )}
            </div>
            <p></p>
            <div>
              <strong>City</strong>{" "}
              {isEditing ? (
                <input
                  type="text"
                  className="form-control"
                  value={userInfo.city}
                  onChange={(e) =>
                    setUserInfo({ ...userInfo, city: e.target.value })
                  }
                />
              ) : (
                <p className="form-control-plaintext">{userInfo.city}</p>
              )}
            </div>
            <br />
          </div>

          <div className="w-50">
            <div>
              <strong>Registration Date</strong>{" "}
              <p className="form-control-plaintext">
                {formatDate(userInfo.registrationdate)}
              </p>
            </div>
            <p></p>
            <div>
              <strong>Password</strong>{" "}
              {isEditing || isEditingPassword ? (
                <p className="form-control-plaintext">********</p>
              ) : (
                <div className="d-flex justify-content-center">
                  <p className="form-control-plaintext">********</p>
                  <img
                    src={editPen}
                    loading="lazy"
                    alt="edit password"
                    className="m-2"
                    onClick={() => setIsEditingPassword(true)}
                    style={{
                      width: "22px",
                      height: "22px",
                      cursor: "pointer",
                    }}
                  />
                </div>
              )}
              {isEditingPassword && (
                <div className="reset-password-wrapperi">
                  {Object.keys(validationErrors).length > 0 && (
                    <div className="error-boxi">
                      {Object.values(validationErrors).map((error, index) => (
                        <p key={index}>{error}</p>
                      ))}
                    </div>
                  )}

                  <div className="reset-password-container">
                    <button
                      type="button"
                      className="reset-password-close-btn"
                      onClick={handleCancelPasswordChange}
                    >
                      &times;
                    </button>
                    <div className="reset-password-headeri">
                      <div className="reset-password-title">Reset Password</div>
                    </div>
                    <div className="reset-password-form">
                      <div className="form-group">
                        <div className="password-input-wrapper">
                          <input
                            type={showOldPassword ? "text" : "password"}
                            className="form-input"
                            value={passwordChange.oldPassword}
                            placeholder="Old Password"
                            onChange={(e) =>
                              setPasswordChange({
                                ...passwordChange,
                                oldPassword: e.target.value,
                              })
                            }
                          />
                          <button
                            type="button"
                            className="password-toggle-btn"
                            onClick={() => setShowOldPassword(!showOldPassword)}
                          >
                            <FontAwesomeIcon
                              icon={showOldPassword ? faEyeSlash : faEye}
                            />
                          </button>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="password-input-wrapper">
                          <input
                            type={showNewPassword ? "text" : "password"}
                            className="form-input"
                            value={passwordChange.newPassword}
                            placeholder="New Password"
                            onChange={(e) =>
                              setPasswordChange({
                                ...passwordChange,
                                newPassword: e.target.value,
                              })
                            }
                          />
                          <button
                            type="button"
                            className="password-toggle-btn"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                          >
                            <FontAwesomeIcon
                              icon={showNewPassword ? faEyeSlash : faEye}
                            />
                          </button>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="password-input-wrapper">
                          <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-input"
                            value={passwordChange.confirmPassword}
                            placeholder="Confirm New Password"
                            onChange={(e) =>
                              setPasswordChange({
                                ...passwordChange,
                                confirmPassword: e.target.value,
                              })
                            }
                          />
                          <button
                            type="button"
                            className="password-toggle-btn"
                            onClick={() =>
                              setShowConfirmPassword(!showConfirmPassword)
                            }
                          >
                            <FontAwesomeIcon
                              icon={showConfirmPassword ? faEyeSlash : faEye}
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        className="reset-password-button"
                        onClick={handleSavePasswordChange}
                      >
                        Save
                      </button>
                      <button
                        className="reset-password-button"
                        onClick={handleCancelPasswordChange}
                        style={{
                          backgroundColor: "#6c757d",
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <p className="form-control-plaintext text-danger">
                {getStatus()}
              </p>
            </div>
          </div>
        </div>
        <br />
        <div className="notification-settings w-100 mb-1">
          <p className="m-0">Receive email notifications about new messages?</p>
          {isEditing ? (
            <div className="radio-options">
              <label>
                <input
                  type="radio"
                  name="messagenotifications"
                  value="yes"
                  checked={userInfo.receivemessagenotifications === "yes"}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      receivemessagenotifications: e.target.value,
                    })
                  }
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="messagenotifications"
                  value="no"
                  checked={userInfo.receivemessagenotifications === "no"}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      receivemessagenotifications: e.target.value,
                    })
                  }
                />
                No
              </label>
            </div>
          ) : (
            <div className="flex justify-content">
              <h4 className="filled-circle">&#8226;</h4>
              <p className="form-control-plaintext m-0">
                {userInfo.receivemessagenotifications}
              </p>
            </div>
          )}
        </div>
        <div className="notification-settings w-100">
          <p className="m-0">Receive email newsletter?</p>
          {isEditing ? (
            <div className="radio-options">
              <label>
                <input
                  type="radio"
                  name="newsletter"
                  value="yes"
                  checked={userInfo.receivenewsletter === "yes"}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      receivenewsletter: e.target.value,
                    })
                  }
                />
                Yes
              </label>
              <label>
                <input
                  type="radio"
                  name="newsletter"
                  value="no"
                  checked={userInfo.receivenewsletter === "no"}
                  onChange={(e) =>
                    setUserInfo({
                      ...userInfo,
                      receivenewsletter: e.target.value,
                    })
                  }
                />
                No
              </label>
            </div>
          ) : (
            <div className="flex justify-content">
              <h4 className="filled-circle">&#8226;</h4>
              <p className="form-control-plaintext m-0">
                {userInfo.receivenewsletter}
              </p>
            </div>
          )}
        </div>
        <br />

        {isEditing && (
          <div className="d-flex justify-content-between">
            <button
              className="btn btn-primary w-75"
              onClick={handleSaveChanges}
            >
              Save changes
            </button>
            <button className="btn btn-secondary" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        )}

        <br />
        <br />

        {isEditing && (
          <div style={{ textAlign: "center" }}>
            <img
              className="w-100"
              src={coconutSeller}
              loading="lazy"
              alt="Background"
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
            <p></p>
            <p style={{ fontSize: "14px" }}>
              <strong>Deleting</strong> your user account will affect all
              services and features related to your account. This may include
              the loss of all saved settings, and other personal information.
            </p>
            <button className="btn btn-danger w-100" onClick={handleDelete}>
              Delete Account
            </button>
          </div>
        )}

        {!isEditing && !isEditingPassword && (
          <div>
            <div className="d-flex justify-content-center mt-3 mb-3">
              {userInfo.isverified === 0 && (
                <button
                  className={`btn btn-warning w-100 ${
                    showVerifyButton ? "" : "d-none"
                  }`}
                  onClick={handleResendVerificationEmail}
                  disabled={disableButton}
                >
                  Resend Verify-email to add items and access messages
                </button>
              )}
            </div>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)", // Aina 2 saraketta
                gridAutoRows: "1fr", // Jokainen rivi skaalautuu samankokoiseksi
                gap: "16px",
                width: "100%",
              }}
            >
              <a style={{ textDecoration: "none" }}>
                <button
                  type="button"
                  className="btn-submit"
                  style={{
                    border: "none",
                    background: "none",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    cursor: "pointer",
                    backgroundColor: "rgb(4, 155, 166)",
                    borderRadius: "5px",
                    color: "white",
                    aspectRatio: "1",
                    width: "100%",
                  }}
                  onClick={handleAddItemButtonClick}
                >
                  <img
                    src={add}
                    loading="lazy"
                    alt="Add sell"
                    style={{
                      filter: "brightness(0) invert(1)",
                    }}
                    height="24px"
                  />
                  Sell your item
                </button>
              </a>

              <button
                type="button"
                className="btn-submit"
                style={{
                  backgroundColor: "rgb(4, 155, 166)",
                  aspectRatio: "1",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleClickMyItems}
              >
                <img
                  src={lista}
                  loading="lazy"
                  alt="Add sell"
                  style={{
                    filter: "brightness(0) invert(1)",
                  }}
                  height="24px"
                />
                My items
              </button>

              <a style={{ textDecoration: "none" }}>
                <button
                  type="button"
                  className="btn-submit"
                  style={{
                    border: "none",
                    background: "none",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    cursor: "pointer",
                    backgroundColor: "rgb(4, 155, 166)",
                    borderRadius: "5px",
                    color: "white",
                    aspectRatio: "1",
                    width: "100%",
                  }}
                  onClick={handleMessagesPageButtonClick}
                >
                  <img
                    src={messagespic}
                    loading="lazy"
                    alt="Messages"
                    style={{
                      filter: "brightness(0) invert(1)",
                    }}
                    height="24px"
                  />
                  Messages
                </button>
              </a>

              <a style={{ textDecoration: "none" }}>
                <button
                  type="button"
                  className="btn-submit"
                  style={{
                    border: "none",
                    background: "none",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    cursor: "pointer",
                    backgroundColor: "rgb(4, 155, 166)",
                    borderRadius: "5px",
                    color: "white",
                    aspectRatio: "1",
                    width: "100%",
                  }}
                  onClick={handleMainPageButtonClick}
                >
                  <img
                    src={search}
                    loading="lazy"
                    alt="Add sell"
                    style={{
                      filter: "brightness(0) invert(1)",
                    }}
                    height="24px"
                  />
                  Search items
                </button>
              </a>
            </div>

            <img
              src={icecreamman}
              loading="lazy"
              alt="my items"
              style={{
                width: "20%",
                margin: "6px auto",
                display: "block",
              }}
            />
            <button className="btn btn-secondary w-100" onClick={handleEdit}>
              <img
                src={editPen}
                loading="lazy"
                alt="Add sell"
                style={{
                  position: "absolute",
                  left: "90px",
                  filter: "brightness(0) invert(1)",
                }}
                height="24px"
              />
              Edit settings
            </button>
            <p></p>
            <button className="btn btn-secondary w-100" onClick={handleLogout}>
              Logout 🔒
            </button>
          </div>
        )}

        {!isEditing && (
          <div style={{ textAlign: "center" }}>
            <p></p>
            <img
              className="w-100"
              src={coconutSeller}
              loading="lazy"
              alt="Background"
              style={{ maxHeight: "100%", maxWidth: "100%" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Profile;
