import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="container mt-4">
      <Helmet>
        <title>Privacy Policy - Pirpana</title>
        <meta
          name="description"
          content="Read Pirpana's privacy policy to understand how we collect, use, and protect your personal information."
        />
        <meta
          name="keywords"
          content="privacy policy, data protection, Pirpana"
        />
      </Helmet>
      <h1>Privacy Policy</h1>
      <p>
        This privacy policy explains how our website Pirpana collects, uses,
        stores, and protects personal information that we may collect from our
        users. This privacy policy is prepared in accordance with the Data
        Privacy Act of 2012 (DPA) of the Philippines.
      </p>

      <h2>Personal Information Collected:</h2>
      <p>
        We may collect various types of personal information, including but not
        limited to:
      </p>
      <ul>
        <li>Personal identifiers (e.g., name, username)</li>
        <li>Contact information (e.g., email address)</li>
        <li>
          Technical information (e.g., IP address, browser information, cookies)
        </li>
        <li>Profile information (e.g., profile picture URL)</li>
      </ul>

      <h2>Purpose of Data Processing:</h2>
      <ul>
        <li>Providing and improving services</li>
        <li>Customer service and support</li>
        <li>Marketing and communication</li>
        <li>User analytics and website optimization</li>
      </ul>

      <h2>Legal Basis:</h2>
      <p>
        Data processing is based on the user's consent, fulfillment of a service
        agreement, legal obligations, or the legitimate interests of the data
        controller.
      </p>

      <h2>Data Sharing:</h2>
      <p>
        Personal information may be shared with third parties, such as technical
        service providers, advertising partners, or law enforcement agencies
        when necessary. If data is transferred outside the Philippines, we
        ensure appropriate safeguards are in place.
      </p>

      <h2>Data Retention Period:</h2>
      <p>
        We retain personal data only as long as necessary to fulfill the
        purposes outlined in this policy or as required by law.
      </p>

      <h2>Newsletter and Informational Emails</h2>
      <p>
        We offer an optional newsletter to keep users informed about updates,
        promotions, and relevant content related to Pirpana. Subscribing to our
        newsletter is entirely voluntary, and users can opt in or out at any
        time through their account settings or by following the unsubscribe link
        provided in each newsletter email.
      </p>
      <p>
        Regardless of your newsletter subscription status, all users will
        receive essential informational emails related to the operation of our
        service. These include important updates, changes to our terms, or other
        notifications necessary for the functionality and security of your
        account. These informational emails are sent to all registered users and
        cannot be opted out of while your account is active.
      </p>
      <p>
        You can manage your communication preferences, including the option to
        subscribe or unsubscribe from the newsletter, by accessing your account
        settings.
      </p>

      <h2>Cookies and Advertising</h2>
      <p>
        We use cookies to enhance your experience on our website and to serve
        personalized advertisements through Google Ads. These cookies may track
        your browsing habits across different websites. You can manage your
        cookie preferences or opt out of personalized advertising in our Cookie
        Consent settings.
      </p>

      <h2>Google Analytics:</h2>
      <p>
        We use Google Analytics to collect and analyze data about how users
        interact with our website. Google Analytics helps us understand user
        behavior and improve our website's performance and content. Google
        Analytics collects information such as IP addresses, device information,
        browser types, and pages visited.
      </p>
      <p>
        Google Analytics uses cookies to track this data. You can learn more
        about Google Analytics and its data practices by visiting the{" "}
        <a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "rgb(13, 119, 108)", textDecoration: "none" }}
        >
          Google Analytics Privacy Policy
        </a>
        .
      </p>
      <p>
        If you do not wish to have Google Analytics collect your data, you can
        opt-out by using the Google Analytics Opt-out Browser Add-on available
        at{" "}
        <a
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "rgb(13, 119, 108)", textDecoration: "none" }}
        >
          Google Analytics Opt-out
        </a>
        .
      </p>

      <h2>User Rights:</h2>
      <p>
        Users have the right to access their personal data, request corrections
        or deletion, object to data processing, and file a complaint with the
        National Privacy Commission.
      </p>

      <h2>Security Measures:</h2>
      <p>
        We have implemented appropriate technical and organizational security
        measures to protect personal data from unauthorized access, use, or
        disclosure.
      </p>

      <h2>Contact Information:</h2>
      <p>
        If you have any questions about our privacy policy or wish to exercise
        your rights, you can{" "}
        <a
          href="/contact"
          style={{ color: "rgb(13, 119, 108)", textDecoration: "none" }}
        >
          Contact Us
        </a>
      </p>
    </div>
  );
};

export default PrivacyPolicy;
