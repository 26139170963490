import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import itembox from "./images/newitembox.jpg";

interface UserItem {
  closed: string;
  deleted: string;
  iditems: number;
  itemtitle: string;
  price: string;
  sellbuy: string;
  itemdescription: string;
  location_idlocation: string;
  location_info: string;
  dateadded: string;
  lastedited: string;
  itemcondition: string;
  subcategories_idsubcategories: string;
  subcategory: string;
  users_idusers: string;
  username: string;
  imagePaths: string[];
}

const FETCH_INTERVAL = 1000; // 1 second

const UserItemsPage: React.FC = () => {
  const { itemHolderID } = useParams<{ itemHolderID: string }>();
  const [userItems, setUserItems] = useState<UserItem[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [holderName, setHolderName] = useState<string | null>(null);

  const lastFetchTimeRefItems = useRef<number | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserItems = async () => {
      const now = Date.now();

      if (
        !lastFetchTimeRefItems.current ||
        now - lastFetchTimeRefItems.current > FETCH_INTERVAL
      ) {
        lastFetchTimeRefItems.current = now;

        try {
          const itemsResponse = await axios.get(
            `${process.env.REACT_APP_SERVER}/api/useritems`,
            {
              params: { itemHolderID },
            }
          );

          const items: UserItem[] = itemsResponse.data;

          if (items.length > 0) {
            setHolderName(items[0].username);
          }

          const itemIds = items.map((item) => item.iditems);
          const imagesResponse = await axios.post<{ [key: number]: string[] }>(
            `${process.env.REACT_APP_SERVER}/api/itemimages-array`,
            { itemIds }
          );

          const imagesByItem = imagesResponse.data;

          const itemsWithImages = items.map((item) => ({
            ...item,
            imagePaths: imagesByItem[item.iditems] || [],
          }));

          setUserItems(itemsWithImages);
        } catch (error) {
          console.error("Error fetching user items:", error);
          setError("Error fetching user items.");
        }
      }
    };

    fetchUserItems();
    window.history.replaceState({}, document.title, `/`);
  }, [itemHolderID]);

  const handleContactSellerClick = (itemId: number) => {
    const token = localStorage.getItem("token");
    const verified = localStorage.getItem("isVerified");
    const itemData = userItems.find((item) => item.iditems === itemId);
    if (token && verified === "1") {
      if (itemData) {
        localStorage.setItem("selectedItem", JSON.stringify(itemData));
        localStorage.setItem("lastItem", JSON.stringify(itemData));
        navigate("/messages");
      }
    } else if (verified === "0") {
      if (itemData) {
        localStorage.setItem("lastItem", JSON.stringify(itemData));
      }
      alert("Please verify your email first");
      navigate("/login");
    } else {
      if (itemData) {
        localStorage.setItem("lastItem", JSON.stringify(itemData));
      }
      navigate("/login");
    }
  };
  const formatDate = (dateString: string) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div
      className=""
      style={{
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Helmet>
        <title>User's Items - Pirpana</title>
        <meta
          name="description"
          content="Browse all items listed by a specific user on Pirpana. View their products and details of each listing."
        />
        <meta
          name="keywords"
          content="user items, products, listings, Pirpana"
        />
      </Helmet>
      <div
        className=""
        style={{
          width: "100%",
          backgroundColor: "#fff",
          padding: 12,
        }}
      >
        <div className="mb-2">
          <h3 style={{ display: "inline" }}>User </h3>
          <h3 style={{ color: "rgb(0, 139, 139)", display: "inline" }}>
            {holderName}
          </h3>
          <h3 style={{ display: "inline" }}> Items</h3>
        </div>

        {window.innerWidth > 500 ? (
          <div>
            <p>
              Here you can see the selected user's items. The items are valid
              for 6 months, after which they are automatically removed from our
              system. If you want to find more items based on different search
              criteria, please go to the Pirpana main page, where you can set
              filters to discover items listed by users.
            </p>
          </div>
        ) : (
          <div>
            <p className="tsm">
              Here you can see the selected user's items. The items are valid
              for 6 months, after which they are automatically removed from our
              system. If you want to find more items based on different search
              criteria, please go to the Pirpana main page, where you can set
              filters to discover items listed by users.
            </p>
          </div>
        )}
      </div>
      <div
        className="container mb-0"
        style={{
          width: "98%",
          maxWidth: "900px",
          backgroundColor: "transparent",
          borderRadius: "10px",
          padding: "16px",
          marginBottom: 24,
        }}
      >
        <div className="user-items">
          {userItems.length > 0 ? (
            userItems.map((item) => (
              <div
                className="container"
                style={{
                  width: "99%",
                  backgroundColor: "#fff",
                  borderRadius: "12px",
                  boxShadow: "0 2px 12px rgba(0, 0, 0, 0.2)",
                  padding: "16px",
                  marginBottom: 24,
                }}
              >
                <div key={item.iditems} className="item-details mb-4">
                  <div className="d-flex justify-content-between">
                    <h4 className="card-title">{item.itemtitle}</h4>
                    <button
                      type="button"
                      className="btn-submit"
                      style={{
                        backgroundColor: "rgb(13, 119, 108)",
                        borderRadius: "12px",
                        color: "#fff",
                        marginTop: "-10px",
                        marginRight: "-10px",
                        whiteSpace: "nowrap",
                        width: 120,
                        height: 44,
                      }}
                      onClick={() => handleContactSellerClick(item.iditems)}
                    >
                      Contact Seller
                    </button>
                  </div>
                  <p className="card-text">
                    <strong>{item.price}</strong> PHP
                  </p>
                  <p className="card-text m-3">
                    {item.itemdescription.length > 100
                      ? `${item.itemdescription.substring(0, 100)}...`
                      : item.itemdescription}
                  </p>
                  <p className="card-text m-0">
                    <strong>Category:</strong> {item.subcategory}
                  </p>
                  {item.itemcondition && (
                    <p className="card-text">
                      <strong>Condition:</strong> {item.itemcondition}
                    </p>
                  )}
                  <p className="card-text m-0 mt-3">
                    {item.sellbuy === "sell" ? (
                      <>
                        User{" "}
                        <b style={{ color: "rgb(0, 139, 139)" }}>
                          {item.username}
                        </b>{" "}
                        is selling this item
                      </>
                    ) : (
                      <>
                        User{" "}
                        <b style={{ color: "rgb(0, 139, 139)" }}>
                          {item.username}
                        </b>{" "}
                        is looking to buy this item
                      </>
                    )}
                  </p>
                  <p className="card-text">
                    <strong>Location:</strong> {item.location_info}
                  </p>
                  <p className="card-text tsm m-0">
                    <strong className="tsm">Added:</strong>{" "}
                    {formatDate(item.dateadded)}
                  </p>
                  <p className="card-text tsm mb-0">
                    <strong className="tsm">Last edited:</strong>{" "}
                    {formatDate(item.lastedited)}
                  </p>
                  <p className="card-text tsm mb-4">
                    <strong className="tsm">Item id:</strong> {item.iditems}
                  </p>
                  <div className="image-gallery">
                    {item.imagePaths.length > 0 ? (
                      item.imagePaths.map((imagePath, index) => (
                        <img
                          key={index}
                          src={`${process.env.REACT_APP_SERVER}/server/${imagePath}`}
                          loading="lazy"
                          alt={`Item image ${index + 1}`}
                          className="img-fluid"
                          style={{
                            width: "100%",
                            height: "auto",
                            marginBottom: "10px",
                          }}
                        />
                      ))
                    ) : (
                      <div
                        style={{
                          position: "relative",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={itembox}
                          loading="lazy"
                          alt="item"
                          style={{
                            height: 120,
                            width: "100%",
                            borderRadius: "12px",
                            filter: "grayscale(100%)",
                            objectFit: "cover",
                          }}
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "10%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "gray",
                            fontSize: "16px",
                          }}
                        >
                          no images
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No items found.</p>
          )}
        </div>
        <div className="border p-0 mt-4 mb-4"></div>
      </div>
    </div>
  );
};

export default UserItemsPage;
