import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import sanitizeHtml from "sanitize-html";

interface TokenPayload {
  email: string;
  username: string;
  exp: number; // expiration timestamp
}

const PostiSenderTool: React.FC = () => {
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [isSent, setIsSent] = useState(false);
  const [sentData, setSentData] = useState<number | null>(null);
  const [email, setEmail] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [topic, setTopic] = useState<string>("");
  const navigate = useNavigate();

  const messageRef = useRef<HTMLTextAreaElement>(null);

  const token = localStorage.getItem("token");
  const isVerified = localStorage.getItem("isVerified");

  useEffect(() => {
    if (token && isVerified === "1") {
      try {
        const decodedToken = jwtDecode<TokenPayload>(token);
        setEmail(decodedToken.email);
        setUsername(decodedToken.username);
        if (!decodedToken.email.endsWith("@pirpana.com")) {
          alert("No access rights");
          navigate("/");
        }
      } catch (error) {
        console.error("Invalid token", error);
        navigate("/");
      }
    } else {
      alert("No access rights");
      navigate("/");
    }
  }, [navigate, token]);

  const handleSend = async () => {
    if (topic !== "") {
      return;
    }
    const confirmation = window.confirm(
      "Are you sure you want to send the email to users?"
    );
    if (!confirmation) return;

    const code1 = prompt("Enter code number 1");
    if (code1 !== process.env.REACT_APP_CODE1) return;

    const code2 = prompt("Enter code number 2");
    if (code2 !== process.env.REACT_APP_CODE2) return;

    const sanitizedMessage = sanitizeHtml(message, {
      allowedTags: [], // Voit sallia haluamasi HTML-tagit tai jättää tyhjäksi, jos et halua sallia mitään.
      allowedAttributes: {}, // Sama koskee attribuutteja, voit jättää tyhjäksi.
    });

    try {
      const apiUrl = `${process.env.REACT_APP_SERVER}/api/send-posti-newsletter`;

      const response = await axios.post(
        apiUrl,
        {
          title,
          message: sanitizedMessage,
        },
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : undefined,
            "Content-Type": "application/json",
          },
        }
      );

      setSentData(response.data.sentEmails);
      setIsSent(true);
    } catch (error) {
      console.error("Delivery failed", error);
      alert("Sending the email failed");
    }
  };

  const adjustTextAreaHeight = () => {
    if (messageRef.current) {
      messageRef.current.style.height = "auto";
      messageRef.current.style.height = `${messageRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextAreaHeight(); // Säädetään textarea:n korkeus aina kun message muuttuu
  }, [message]);

  const formattedMessage = message.replace(/\n/g, "<br />");

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        padding: "2rem",
        backgroundColor: "gray",
        zIndex: 9999,
        overflow: "auto",
      }}
    >
      <div
        className="container"
        style={{
          maxWidth: "600px",
          padding: "2rem",
          backgroundColor: "#007bff",
        }}
      >
        {!isSent ? (
          <>
            <h1
              style={{
                textAlign: "center",
                color: "white",
                marginBottom: "2rem",
              }}
            >
              Send NewsLetter for 1000 active users
            </h1>

            <form>
              <div style={{ marginBottom: "1rem" }}>
                <label style={{ color: "white" }}>Title</label>
                <input
                  type="text"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter title"
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    marginTop: "0.5rem",
                    borderRadius: "4px",
                  }}
                />
              </div>
              <div>
                <input
                  type="text"
                  id="topic"
                  style={{ display: "none" }}
                  value={topic}
                  onChange={(e) => setTopic(e.target.value)}
                  tabIndex={-1}
                  autoComplete="off"
                />
              </div>
              <div style={{ marginBottom: "1rem" }}>
                <label style={{ color: "white" }}>Message</label>
                <textarea
                  ref={messageRef}
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                    adjustTextAreaHeight();
                  }}
                  placeholder="Enter message"
                  style={{
                    width: "100%",
                    padding: "0.5rem",
                    marginTop: "0.5rem",
                    borderRadius: "4px",
                    overflow: "hidden",
                    resize: "none",
                  }}
                  rows={3}
                />
              </div>

              <button
                type="button"
                onClick={handleSend}
                style={{
                  padding: "0.5rem 1rem",
                  marginTop: "2rem",
                  backgroundColor: "white",
                  color: "black",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                Send
              </button>
            </form>
            <div
              style={{
                paddingTop: "2rem",
                marginBottom: "5rem",
              }}
            >
              <h2 style={{ color: "white", fontSize: "2rem" }}>
                Preview window
              </h2>
              <div
                style={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "2px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <h2>{title}</h2>
                <p dangerouslySetInnerHTML={{ __html: formattedMessage }} />
              </div>
            </div>
          </>
        ) : (
          <div style={{ textAlign: "center", color: "white" }}>
            NewsLetter for 1000 active users has been sent for {sentData} users.
          </div>
        )}
      </div>
    </div>
  );
};

export default PostiSenderTool;
