import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

const CookieConsent: React.FC = () => {
  const [preferences, setPreferences] = useState({
    essential: true, // Pakolliset evästeet, aina valittuna
    analytics: false,
    ads: false,
    personalization: false,
    security: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const consent = getCookie("cookie-consent");
    if (consent) {
      setPreferences(JSON.parse(consent));
    }
  }, []);

  const loadGoogleAds = () => {
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    document.head.appendChild(script);

    script.onload = () => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    };
  };

  const loadGoogleAnalytics = () => {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`; // GOOGLE ANALYTICS G-ID
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      window.gtag("js", new Date());
      window.gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS_ID); // GOOGLE ANALYTICS G-ID
    };
  };

  const handleAcceptAll = () => {
    const allPreferences = {
      essential: true,
      analytics: true,
      ads: true,
      personalization: true,
      security: true,
    };
    setCookie("cookie-consent", JSON.stringify(allPreferences), 365);

    // GOOGLE ADS
    // loadGoogleAds();

    loadGoogleAnalytics();

    navigate("/");
  };

  // KEHISTYSYMPÄRISTÖÖN voi poistaa ohjelman julkaistua
  const deleteAllCookies = () => {
    const cookies = document.cookie.split(";");

    cookies.forEach((cookie) => {
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    });
  };

  const handleRejectAllButEssential = () => {
    setCookie("cookie-consent", JSON.stringify({ essential: true }), 365);
    //deleteAllCookies();
    navigate("/");
  };

  const handleSavePreferences = () => {
    setCookie("cookie-consent", JSON.stringify(preferences), 365);

    // GOOGLE ADS
    // if (preferences.ads) {
    //   loadGoogleAds(); // Aktivoi mainoslogiikka, jos mainokset valittu
    // }

    if (preferences.analytics) {
      loadGoogleAnalytics();
    }

    navigate("/");
  };

  const handlePreferenceChange = (category: string) => {
    setPreferences((prevPrefs) => ({
      ...prevPrefs,
      [category]: !prevPrefs[category as keyof typeof prevPrefs],
    }));
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
        overflowY: "auto",
      }}
    >
      <Helmet>
        <title>Cookie Consent - Pirpana</title>
        <meta
          name="description"
          content="Learn about our cookie policy and how we use cookies to enhance your experience on Pirpana."
        />
        <meta
          name="keywords"
          content="cookie consent, cookies, privacy policy, Pirpana"
        />
      </Helmet>
      <div
        className="bg-light p-4 container"
        style={{
          width: 600,
          borderRadius: 8,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <h5>Cookie Settings 🍪</h5>
        <p>
          This website uses cookies to ensure the proper functioning of our
          site, to personalize content and ads, to provide social media
          features, and to analyze our traffic. You can manage your preferences
          below. For more information, please read our{" "}
          <a
            href="/privacy-policy"
            style={{ textDecoration: "none", color: "rgb(13, 119, 108)" }}
          >
            Privacy Policy
          </a>
          .
        </p>
        <div className="text-end">
          <button
            className="btn"
            onClick={handleAcceptAll}
            style={{
              backgroundColor: "rgb(13, 119, 108)",
              cursor: "pointer",
              color: "white",
              width: 240,
            }}
          >
            Accept All
          </button>
        </div>
        <div className="mt-3">
          <div className="p-2 border mb-3" style={{ borderRadius: 12 }}>
            <h6>Essential Cookies (Required)</h6>
            <p>
              These cookies are necessary for the website to function and cannot
              be switched off in our systems.
            </p>
            <div className="form-check" style={{ marginLeft: 6 }}>
              <input
                type="checkbox"
                className="form-check-input"
                checked={preferences.essential}
                disabled
              />
              <label className="form-check-label">Essential cookies</label>
            </div>
          </div>
          <div className="p-2 border mb-3" style={{ borderRadius: 12 }}>
            <h6>Analytics Cookies</h6>
            <p>
              We use Google Analytics to understand how users interact with our
              website. Google Analytics collects data such as your IP address,
              device information, duration of your visit, and actions you
              perform on the site. This information is used to improve the
              usability and content of our site.
            </p>
            <div className="form-check" style={{ marginLeft: 6 }}>
              <input
                type="checkbox"
                className="form-check-input"
                checked={preferences.analytics}
                onChange={() => handlePreferenceChange("analytics")}
              />
              <label className="form-check-label">Google Analytics</label>
            </div>
          </div>
          <div className="p-2 border mb-3" style={{ borderRadius: 12 }}>
            <h6>Advertising Cookies</h6>
            <p>
              We use Google Ads to deliver personalized advertisements based on
              your browsing behavior. Google Ads collects data on your
              activities across different websites and uses this information for
              targeted advertising.
            </p>
            <div className="form-check" style={{ marginLeft: 6 }}>
              <input
                type="checkbox"
                className="form-check-input"
                checked={preferences.ads}
                onChange={() => handlePreferenceChange("ads")}
              />
              <label className="form-check-label">Google Ads</label>
            </div>
          </div>
          <div className="p-2 border mb-3" style={{ borderRadius: 12 }}>
            <h6>Personalization Cookies</h6>
            <p>
              These cookies allow the website to remember choices you make and
              provide enhanced, more personal features.
            </p>
            <div className="form-check" style={{ marginLeft: 6 }}>
              <input
                type="checkbox"
                className="form-check-input"
                checked={preferences.personalization}
                onChange={() => handlePreferenceChange("personalization")}
              />
              <label className="form-check-label">
                Personalization cookies
              </label>
            </div>
          </div>
          <div className="p-2 border mb-3" style={{ borderRadius: 12 }}>
            <h6>Security Cookies</h6>
            <p>
              These cookies are used to ensure the security of our website and
              prevent fraudulent activities.
            </p>
            <div className="form-check" style={{ marginLeft: 6 }}>
              <input
                type="checkbox"
                className="form-check-input"
                checked={preferences.security}
                onChange={() => handlePreferenceChange("security")}
              />
              <label className="form-check-label">Security cookies</label>
            </div>
          </div>
        </div>

        <div className="mt-4">
          <button
            className="btn btn-primary m-2"
            style={{ width: 240 }}
            onClick={handleSavePreferences}
          >
            Save Preferences
          </button>
          <button
            className="btn btn-secondary m-2"
            style={{ width: 240 }}
            onClick={handleRejectAllButEssential}
          >
            Reject All Except Essential
          </button>
          <p className="text-center m-4" style={{ color: "white" }}>
            -
          </p>
        </div>
      </div>
    </div>
  );
};

// Cookie functions remain the same as before
const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; path=/`;
};

const getCookie = (name: string): string | null => {
  const match = document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)");
  return match ? decodeURIComponent(match.pop() || "") : null;
};

export default CookieConsent;
