import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";

// Cookie functions
const setCookie = (name: string, value: string, days: number) => {
  const expires = new Date(Date.now() + days * 864e5).toUTCString();
  document.cookie = `${name}=${encodeURIComponent(
    value
  )}; expires=${expires}; path=/`;
};

const getCookie = (name: string): string | null => {
  const match = document.cookie.match("(^|;)\\s*" + name + "\\s*=\\s*([^;]+)");
  return match ? decodeURIComponent(match.pop() || "") : null;
};

const CookieConsentPopup: React.FC = () => {
  const [showPopup, setShowPopup] = useState(true);

  const navigate = useNavigate();

  const loadGoogleAds = () => {
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    document.head.appendChild(script);

    script.onload = () => {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    };
  };

  const loadGoogleAnalytics = () => {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`; // GOOGLE ANALYTICS G-ID
    document.head.appendChild(script);

    script.onload = () => {
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;
      window.gtag("js", new Date());
      window.gtag("config", process.env.REACT_APP_GOOGLE_ANALYTICS_ID); // GOOGLE ANALYTICS G-ID
    };
  };

  const handleAcceptAll = () => {
    const allPreferences = {
      essential: true,
      analytics: true,
      ads: true,
      personalization: true,
      security: true,
    };
    setCookie("cookie-consent", JSON.stringify(allPreferences), 365);
    setShowPopup(false);

    // GOOGLE ADS
    // loadGoogleAds();

    loadGoogleAnalytics();
  };

  const handleCookieSettings = () => {
    navigate("/CookieConsent");
    setShowPopup(false);
  };

  if (!showPopup) {
    return null;
  }

  return (
    <div
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        position: "fixed",
        zIndex: 9999,
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        justifyContent: "center",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <div
        className="bg-light p-4"
        style={{
          borderRadius: 12,
          width: "80%",
          maxWidth: "600px",
          position: "fixed",
          bottom: 0,
          margin: "2rem",
        }}
      >
        <h5>Cookie Consent</h5>
        <p>
          This website uses cookies to ensure the proper functioning of our
          site, to personalize content and ads, to provide social media
          features, and to analyze our traffic. You can manage your preferences
          below. For more information, please read our{" "}
          <a
            href="/privacy-policy"
            className="text-primary"
            style={{ textDecoration: "none" }}
          >
            Privacy Policy
          </a>
          .
        </p>
        <div className="text-end">
          <button
            className="btn m-1"
            onClick={handleAcceptAll}
            style={{
              backgroundColor: "rgb(13, 119, 108)",
              cursor: "pointer",
              color: "white",
              width: 180,
            }}
          >
            Accept All
          </button>
          <button
            className="btn btn-secondary m-1"
            onClick={handleCookieSettings}
            style={{
              cursor: "pointer",
              color: "white",
              width: 180,
            }}
          >
            Cookie Settings
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsentPopup;
