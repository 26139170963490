import React, { useEffect, useState, FormEvent, useRef } from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import axios from "axios";
import bgimage from "./images/newitemspic.png";
import newitembox from "./images/newitembox.jpg";
import { useNavigate } from "react-router-dom";

axios.defaults.baseURL = `${process.env.REACT_APP_SERVER}`;

interface Subcategory {
  id: string;
  name: string;
}

interface CategoryWithSubcategories {
  category: string;
  subcategories: Subcategory[];
}

const FETCH_INTERVAL = 1000; // 1 second

const NewItemFile: React.FC = () => {
  const [itemTitle, setItemTitle] = useState("");
  const [itemDescription, setItemDescription] = useState("");
  const [price, setPrice] = useState("");
  const [priceValid, setPriceValid] = useState(true);
  const [category, setCategory] = useState<string>("");
  const [subcategory, setSubcategory] = useState<string>("");
  const [itemCondition, setItemCondition] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const [sellBuy, setSellBuy] = useState<string>("sell");
  const [locations, setLocations] = useState<
    { location: string; idlocation: string }[]
  >([]);
  const [idlocation, setIdlocation] = useState<string>("");
  const [categoriesWithSubcategories, setCategoriesWithSubcategories] =
    useState<CategoryWithSubcategories[]>([]);

  const lastFetchTimeRefCategories = useRef<number | null>(null);
  const lastFetchTimeRefLocations = useRef<number | null>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [locationValue, setLocationValue] = useState<string>("");
  const [loadingLocations, setLoadingLocations] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [topic, setTopic] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    const iv = localStorage.getItem("isVerified");

    if (!token || iv !== "1") {
      navigate("/login");
      return;
    }

    const fetchCategories = async () => {
      const now = Date.now();
      if (
        !lastFetchTimeRefCategories.current ||
        now - lastFetchTimeRefCategories.current > FETCH_INTERVAL
      ) {
        lastFetchTimeRefCategories.current = now;
        try {
          const response = await axios.get("/api/get-categories");
          setCategoriesWithSubcategories(response.data);
        } catch (error) {
          console.error("Error fetching categories and subcategories:", error);
        }
      }
    };

    fetchCategories();
  }, [navigate]);

  useEffect(() => {
    const fetchLocations = async () => {
      setLoadingLocations(true);
      const now = Date.now();
      if (
        !lastFetchTimeRefLocations.current ||
        now - lastFetchTimeRefLocations.current > FETCH_INTERVAL
      ) {
        lastFetchTimeRefLocations.current = now;
        try {
          const response = await axios.get("/api/get-locations");
          setLocations(response.data);
        } catch (error) {
          console.error("Error fetching locations:", error);
        } finally {
          setLoadingLocations(false);
        }
      } else {
        setLoadingLocations(false);
      }
    };

    if (showModal) {
      fetchLocations();
    }
  }, [showModal]);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategory(e.target.value);
    setSubcategory("");
  };

  const filteredLocations = locations.filter((loc) =>
    loc.location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedImages = Array.from(e.target.files);

      // Tarkista, ettei yli 6 kuvaa voida ladata
      if (images.length + selectedImages.length > 6) {
        alert("Voit ladata enintään 6 kuvaa.");
        return;
      }

      // Kuvien puristusfunktio
      const compressImage = (file: File) => {
        return new Promise<Blob>((resolve) => {
          const img = document.createElement("img");
          img.src = URL.createObjectURL(file);
          img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            if (!ctx) {
              throw new Error("Failed to get 2D context");
            }

            const MAX_WIDTH = 800;
            const scaleSize = MAX_WIDTH / img.width;
            canvas.width = MAX_WIDTH;
            canvas.height = img.height * scaleSize;

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            let quality = 0.7;

            canvas.toBlob(
              (blob) => {
                if (!blob) {
                  resolve(new Blob());
                  return;
                }

                // Tarkistetaan ja säädetään kuvan kokoa
                while (blob.size > 1 * 1024 * 1024) {
                  quality -= 0.1; // Vähennetään laatua

                  if (quality <= 0) {
                    break; // Ei voi enää vähentää laatua
                  }

                  canvas.toBlob(
                    (newBlob) => {
                      if (!newBlob) {
                        return;
                      }
                      blob = newBlob; // Päivitetään blob uudella koon mukaan
                    },
                    "image/jpeg",
                    quality
                  );
                }

                resolve(blob);
              },
              "image/jpeg",
              quality
            );
          };
        });
      };

      // Purista kuvat ja lue esikatselukuvat
      const compressedImages = await Promise.all(
        selectedImages.map(async (image) => {
          const compressedBlob = await compressImage(image);
          return new File([compressedBlob], image.name, { type: image.type });
        })
      );

      // Päivitä kuvat vain kerran
      setImages((prevImages) => [...prevImages, ...compressedImages]);
    }
  };

  const handleRemoveImage = (index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (topic !== "") {
      return;
    }

    if (idlocation === "") {
      alert("Choose Location *");
      return;
    }
    // Hae userId localStoragesta
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");

    if (!userId || !token) {
      alert("User ID or token not found in localStorage");
      return;
    }

    // Luo FormData-objekti ja lisää siihen kaikki lomakkeen tiedot
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("itemTitle", itemTitle);
    formData.append("itemDescription", itemDescription);
    if (price === "") {
      formData.append("price", "0");
    } else {
      formData.append("price", price);
    }
    formData.append("subcategory", subcategory);
    formData.append("itemCondition", itemCondition);
    formData.append("sellBuy", sellBuy);
    formData.append("idlocation", idlocation);

    // Lisää kuvat oikeassa järjestyksessä FormData-objektiin
    images.forEach((image) => {
      formData.append("images", image);
    });

    setIsSubmitting(true);
    // Lähetä FormData backendiin POST-pyynnöllä
    axios
      .post(`${process.env.REACT_APP_SERVER}/api/pushitem`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", // Tärkeää asettaa oikea content type
        },
      })
      .then((response) => {
        // Tarkista vastauksesta tarvittavat tiedot
        const { success, itemId } = response.data;

        if (success) {
          setIsSubmitting(false);
          alert(
            "Item successfully listed.\nYour item notice is valid for 6 months."
          );
          navigate("/myitems");
        } else {
          throw new Error("Item listing failed.");
        }
      })
      .catch((error) => {
        console.error("Error in data transmission:", error);
        alert("Error in product transmission.");
      });
  };

  const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;
    value = value.replace(",", ".");
    if (value.length > 11) {
      return;
    }
    // Tarkista, että arvo on DECIMAL(11,2) -muotoinen
    const isValidPrice = /^\d{1,11}(\.\d{1,2})?$/.test(value);
    setPrice(value);
    setPriceValid(isValidPrice || value === ""); // Sallitaan myös tyhjä arvo
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Helmet>
        <title>New Item - Pirpana</title>
        <meta
          name="description"
          content="Add a new item to your listings on Pirpana. Provide details such as title, description, price, and images to showcase your item."
        />
        <meta name="keywords" content="add new item, list product, Pirpana" />
      </Helmet>
      <div className="new-item-container m-3 w-100">
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ marginBottom: 20, marginTop: -10 }}
        >
          <h2 className="title">New Item</h2>
          <img
            src={newitembox}
            loading="lazy"
            alt="Background"
            style={{
              width: 60,
              height: 60,
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
        <p
          style={{
            fontSize: 9,
            margin: 0,
          }}
        >
          Fields marked with an asterisk * are mandatory
        </p>
        <form
          onSubmit={handleSubmit}
          className="new-item-form"
          encType="multipart/form-data"
        >
          <div className="form-group small">
            <label htmlFor="itemTitle">Item Title *</label>
            <input
              type="text"
              id="itemTitle"
              value={itemTitle}
              onChange={(e) => setItemTitle(e.target.value)}
              required
              maxLength={99}
              className="form-control"
            />
          </div>
          <div>
            <input
              type="text"
              id="topic"
              style={{ display: "none" }}
              value={topic}
              onChange={(e) => setTopic(e.target.value)}
              tabIndex={-1}
              autoComplete="off"
            />
          </div>
          <div className="form-group small">
            <label htmlFor="itemDescription">Item Description</label>
            <textarea
              id="itemDescription"
              value={itemDescription}
              onChange={(e) => setItemDescription(e.target.value)}
              rows={4}
              className="form-control"
            />
          </div>
          <div className="form-group small">
            <label htmlFor="category">Category *</label>
            <select
              id="category"
              value={category}
              onChange={handleCategoryChange}
              required
              className="form-control"
            >
              <option value=""></option>
              {categoriesWithSubcategories.map((categoryWithSubcategories) => (
                <option
                  key={categoryWithSubcategories.category}
                  value={categoryWithSubcategories.category}
                >
                  {categoryWithSubcategories.category}
                </option>
              ))}
            </select>
          </div>
          {category && (
            <div className="form-group small">
              <label htmlFor="subcategory">Subcategory *</label>
              <select
                id="subcategory"
                value={subcategory}
                onChange={(e) => setSubcategory(e.target.value)}
                required
                className="form-control"
              >
                <option value=""></option>
                {categoriesWithSubcategories
                  .find((cat) => cat.category === category)
                  ?.subcategories.map((subcategory: Subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.name}
                    </option>
                  ))}
              </select>
            </div>
          )}
          <div className="form-group small">
            <label htmlFor="itemCondition">Item Condition</label>
            <select
              id="itemCondition"
              value={itemCondition}
              onChange={(e) => setItemCondition(e.target.value)}
              className="form-control"
            >
              <option value=""></option>
              <option value="new">New</option>
              <option value="used">Used</option>
            </select>
          </div>

          <div className="small">
            <label htmlFor="location">Location *</label>
            <div>
              <label
                htmlFor="location"
                className="form-control mb-2 location-label"
                onClick={() => setShowModal(true)}
              >
                {locationValue || ""}
              </label>
            </div>
          </div>
          {showModal && (
            <div className="type-message-wrapperi" style={{ zIndex: 1000 }}>
              <div className="type-location-containeri">
                <label htmlFor="location">Location search</label>
                <input
                  type="text"
                  placeholder="type location..."
                  value={locationValue}
                  onChange={(e) => {
                    setLocationValue(e.target.value);
                    setSearchTerm(e.target.value);
                  }}
                  className="form-control mb-1"
                />
                {loadingLocations ? (
                  <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: "150px", marginBottom: "250px" }}
                  >
                    <div className="loader"></div>
                  </div>
                ) : (
                  <div className="location-optionsit p-2">
                    {filteredLocations.length > 0 ? (
                      filteredLocations.map((loc) => (
                        <div key={loc.idlocation} className="form-check">
                          <input
                            type="radio"
                            id={loc.idlocation}
                            name="location"
                            value={loc.idlocation}
                            checked={idlocation === loc.idlocation}
                            onChange={(e) => {
                              setIdlocation(e.target.value);
                              setLocationValue(loc.location);
                            }}
                            onClick={() => setShowModal(false)}
                            className="form-check-input"
                          />
                          <label
                            htmlFor={loc.idlocation}
                            className="form-check-label"
                          >
                            {loc.location}
                          </label>
                        </div>
                      ))
                    ) : (
                      <p>No locations found</p>
                    )}
                  </div>
                )}
                <p className="m-0" onClick={() => setShowModal(false)}>
                  ← back
                </p>
              </div>
            </div>
          )}

          <div className="d-flex justify-content-between">
            <div className="form-group small" style={{ width: 130 }}>
              <label htmlFor="price">Price PHP</label>
              <input
                type="text"
                id="price"
                value={price}
                onChange={handlePriceChange}
                className={`form-control ${!priceValid ? "is-invalid" : ""}`}
              />
              {!priceValid && (
                <div className="invalid-feedback">
                  Price must be a valid decimal number (up to 2 decimal places).
                </div>
              )}
            </div>
            <div className="form-group small" style={{ width: 130 }}>
              <label htmlFor="sellBuy">Sell or Buy *</label>
              <select
                id="sellBuy"
                value={sellBuy}
                onChange={(e) => setSellBuy(e.target.value)}
                className="form-control"
                required
              >
                <option value="sell">I am selling</option>
                <option value="buy">I am buying</option>
              </select>
            </div>
          </div>
          <div className="form-group small">
            <label htmlFor="images">Images (max 6)</label>
            <input
              type="file"
              id="images"
              onChange={handleImageChange}
              multiple
              accept="image/*"
              className="form-control"
            />
          </div>
          {images.length > 0 && (
            <div className="form-group">
              <label>Selected images</label>
              <div className="d-flex flex-wrap">
                {images.map((image, index) => (
                  <div key={index} className="position-relative m-2">
                    <img
                      src={URL.createObjectURL(image)}
                      loading="lazy"
                      alt={`Preview ${index + 1}`}
                      className="img-thumbnail"
                      style={{ maxWidth: 100, maxHeight: 100 }}
                    />
                    <button
                      type="button"
                      className="btn btn-sm btn-danger position-absolute top-0 end-0"
                      onClick={() => handleRemoveImage(index)}
                      style={{ borderRadius: "50%" }}
                    >
                      &times;
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <p></p>
          {isSubmitting && (
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                height: "100vh",
                width: "100%",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                zIndex: 9999,
              }}
            >
              <div className="loader"></div>
            </div>
          )}
          <button
            type="submit"
            className="btn-submit"
            style={{ backgroundColor: "rgb(13, 119, 108)" }}
          >
            Submit
          </button>
        </form>
        {/* Muut sisältökuvat */}
        <img
          src={bgimage}
          loading="lazy"
          alt="Background"
          style={{ width: "100%" }}
        />
      </div>
    </div>
  );
};

export default NewItemFile;
